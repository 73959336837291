'use client'

import { ErrorComponent } from 'monitoring/components/ErrorComponent/ErrorComponent'

export default function ErrorPage({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) {
	return <ErrorComponent error={error} reset={reset} />
}
